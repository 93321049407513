export enum Identifier {
  TYPE_API_KEY = 'apiKey',
  TYPE_TOKEN = 'token',
  TYPE_USER = 'user',
  TYPE_CLINIC = 'clinic',
  TYPE_CLINIC_GROUP_ID = 'clinicGroupId',
  TYPE_CLINIC_ID = 'clinicId',
  TYPE_LANGUAGE = 'culture',
  TYPE_FRANCHISE_ID = 'clinicGroupFranchiseId',
  TYPE_FRANCHISE_LANGUAGE_IDS = 'clinicFranchiseSupportedLanguageIds',
  TYPE_VISIBLE_MENUS = 'visibleMenus',
  TYPE_ENABLED_ACTIONS = 'enabledActions',
  TYPE_CLINIC_MODULES = 'clinicModules',
  //TYPE_STACK_INFO_KEY = 'stackInfo',
  TYPE_LOGIN_STAFF = 'staffInfo',
  TYPE_FRANCHISE_TOKEN = 'franchiseToken',
  AD_USER = 'ad_user',
  TYPE_ChatToken = 'authJwtToken',
  TYPE_IS_SSO_LOGIN = 'is_sso_login',
  TYPE_NAV_BAR_OPEN = 'navBarState',
  TYPE_CLINIC_MASTER_PARTNER_ID = 'clinicMasterPartnerId',
  TYPE_OTHER_SUPPORTED_LANGUAGES = 'otherSupportedLanguages',

  //URLs
  TYPE_COAPI_URL = 'coApiUrl',
  TYPE_CMAPI_URL = 'cmApiUrl',
  TYPE_GRAPTHAPI_URL = 'graphApi',
  TYPE_SSOAPI_URL = 'ssoapiUrl',
  TYPE_SIGNALRAPI_URL = 'signalRwebApiBaseUri',
  TYPE_REPORTAPI_URL = 'reportApiUrl',
  TYPE_FILEAPI_URL = 'fileApiUrl',
  TYPE_SETTINGS_URL = 'clinicMasterSettingsUrl',
  TYPE_POWERBIAPI_URL = 'powerBiBaseUrl',

  CONFIGURATION_MENU_CLINIC_ID = 'configurationMenuClinicId',
}
