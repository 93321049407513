import { environment } from '../environments/environment';
import { Identifier } from './shared/models/storage.models';

export class APIRoutesConfig {
  public static get coApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_COAPI_URL) || environment.coApiUrl || '';
  }
  public static get cmApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_CMAPI_URL) || '';
  }
  public static get clinicMasterSettingsUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_SETTINGS_URL) || '';
  }
  public static get fileApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_FILEAPI_URL) || '';
  }
  public static get reportApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_REPORTAPI_URL) || '';
  }
  public static get ssoapiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_SSOAPI_URL) || '';
  }
  public static get addaGraphApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_GRAPTHAPI_URL) || '';
  }
  public static get signalRApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_SIGNALRAPI_URL) || '';
  } public static get powerBiApiUrl(): string {
    return sessionStorage.getItem(Identifier.TYPE_POWERBIAPI_URL) || '';
  }
}
